import React, { useState, useRef, useEffect, useCallback } from "react";
import * as style from "../styles/404.module.css";
import Header from "../components/header/Header";
import Rule from "../components/header/Rule";
import Pill from "../components/pill/Pill";
import { useResizeDetector } from "react-resize-detector";
const speed = 1.5;

const NotFoundPage = () => {
    const containerRef = useRef(null);
    const targetRef = useRef(null);
    const requestRef = useRef(null);
    const [, setTime] = useState(0);

    const refX = useRef(1);
    const refY = useRef(1);
    const refXSpeed = useRef(speed);
    const refYSpeed = useRef(speed);

    const onResize = useCallback(() => {
        refX.current = 1;
        refY.current = 1;
    }, []);

    const { ref } = useResizeDetector({ onResize });

    useEffect(() => {
        const animate = (time) => {
            if (
                refX.current + targetRef.current.clientWidth >= containerRef.current.clientWidth ||
                refX.current <= 0
            ) {
                refXSpeed.current = -refXSpeed.current;
            }
            if (
                refY.current + targetRef.current.clientHeight >=
                    containerRef.current.clientHeight ||
                refY.current <= 0
            ) {
                refYSpeed.current = -refYSpeed.current;
            }
            setTime(time);
            refX.current = refX.current + refXSpeed.current;
            refY.current = refY.current + refYSpeed.current;
            requestRef.current = requestAnimationFrame(animate);
        };

        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    return (
		<main className={style.notfound} ref={ref}>
			<Header home={false} />
			<Rule />
			<section className={style.container} ref={containerRef}>
				<div
					className={style.wrapper}
					ref={targetRef}
					style={{ left: refX.current + "px", top: refY.current + "px" }}
				>
					<Pill title={"Page Not Found"} weight={500} />
				</div>
			</section>
		</main>
	);
};

export default NotFoundPage;
